/*
 @author Sodasi Web SRL
 @buyFrom themeforest.net

   01. General
   02. Accordion
   03. Graph
   04. Accordion 2
   05. Tabs
   06. Lists
   07. Top Menu
   08. Main Menu
   09. Slider
   10. First Heading
   11. Block 1
   12. Promo Section
   13. From the blog
   14. Parallax
   15. Intro 2
   16. Newsletter
   17. Our features
   18. FullColumn Images
   19. What Students Say
   20. Meet our instructors
   21. Contact
   22. Packages
   23. Clients
   24. Footer
   25. Copywrite Section
   26. Homepage 2
   27. Parallax
   28. Gallery
   29. Block 2
   30. blog item
   31. Inline Slider
   32. Pagination
   33. Sidebar
   34. Recent Articles
   35. Tag Cloud
   36. Newsletter
   37. Blockquote
   38. Post Social Icons
   39. Related Post
   40. Comments
   41. Messages
   42. Lines
   43. Page not found 404
   44. Responsive
 */
body {
	font-family: Lato;
}
a {
	color: #3E4095;
}
p {
	font-size: 14px;
	line-height: 23px;
	margin-top: 15px;
}
figure {
	margin-top: 15px;
}
ul li, ol li {
	line-height: 1.5em;
	font-size: 16px;
}
.btn {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	line-height: 50px;
	padding: 0 30px;
	font-family: Roboto;
}
iframe {
	border: none;
}
hr {
	height: 6px;
	border: none;
	display: block;
	width: 30px;
	background: #3E4095;
	margin: 15px auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	clear: both;
}
h1 {
	color: #3E4095;
	font-size: 2.3em;
	font-weight: bold;
}
h2 {
	color: #3E4095;
}
h3 {
	color: #3E4095;
}
h4 {
	color: #3E4095;
}
h5 {
	color: #3E4095;
}
address strong {
	color: #3E4095;
	font-size: 18px;
}
table thead {
	background: #3E4095;
	color: #fff;
	border: none;
}
table.table thead tr, table.table thead th {
	border: 1px solid #02344c;
}
input[type="file"] {
	background: #3E4095;
	color: #fff;
	padding: 10px;
}
iframe {
	width: 100%;
}
hr.thin {
	border: none;
	height: 1px;
	background: #ccc;
	width: 100%;
	margin: 25px 0;
}
.toRightCol {
	float: right;
}
.alignLeft {
	float: left;
}
.btn_no_style {
	background: none;
	border: none;
}
.leftimg {
	float: left;
	padding: 0 30px 10px 0;
}
.rightimg {
	float: right;
	padding: 0 0 10px 30px;
}
section {
	margin: 0;
	padding: 0;
}
.btn-default {
	background: #3E4095;
background: -moz-linear-gradient(top, #3E4095 0%,#28afa6 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3E4095), color-stop(100%,#28afa6));
background: -webkit-linear-gradient(top, #3E4095 0%,#28afa6 100%);
background: -o-linear-gradient(top,  #3E4095 0%, 	#28afa6 100%);
background: -ms-linear-gradient(top, #3E4095 0%,  	#28afa6 100%);
background: linear-gradient(to bottom,  #3E4095 0%, 	#28afa6 100%);

	color: #fff;
	border: 2px solid #3E4095;
	border-bottom: 2px solid #1d8d86;
	line-height: 48px;
}
.btn-default:hover {
	color: #3E4095;
	background: #fff;
	border: 2px solid #1d8d86;
}
.btn-orange, .btn-white, .packages  .active  .package-btn .btn, .packages  .mouseover .package-btn .btn, .btn-blue-border {
	background: #1e5799;
background: -moz-linear-gradient(top, #1e5799 0%, #BE8D48 0%,  	#BE8D48 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(0%,#BE8D48), color-stop(100%, #BE8D48));
background: -webkit-linear-gradient(top,  #1e5799 0%,  #BE8D48 0%,  	#BE8D48 100%);
background: -o-linear-gradient(top,  #1e5799 0%, #BE8D48 0%, 	#BE8D48 100%);
background: -ms-linear-gradient(top, #1e5799 0%, #BE8D48 0%, 	#BE8D48 100%);
background: linear-gradient(to bottom, #1e5799 0%,  #BE8D48 0%,   	#BE8D48 100%);

	color: white;
}
.btn-orange:hover, .btn-white:hover, .packages  .active  .package-btn .btn:hover, .packages  .mouseover  .package-btn .btn:hover, .btn-blue-border {
	background: #1e5799;
background: -moz-linear-gradient(top, #8E734C 0%,  #8E734C 0%, 	#8E734C 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8E734C), color-stop(0%, #8E734C), color-stop(100%,	#8E734C));
background: -webkit-linear-gradient(top,#8E734C 0%,#8E734C 0%, 	#8E734C 100%);
background: -o-linear-gradient(top, #8E734C 0%, #8E734C 0%,    	#8E734C 100%);
background: -ms-linear-gradient(top,  #8E734C 0%, #8E734C 0%,   	#8E734C 100%);
background: linear-gradient(to bottom, #8E734C 0%,  #8E734C 0%, 	#8E734C 100%);

	color: white;
}
.btn-white {
	border: 1px solid white;
	color: white;
	background: none;
}
.btn-white:hover {
	background: white;
	color: #BE8D48;
	border: 1px solid white;
}
.btn-blue-border {
	border: 2px solid #3E4095;
	background: none;
	color: #3E4095;
}
.btn-blue-border:hover {
	border: 2px solid #3E4095;
	background: #3E4095;
	color: white;
}
.btn-blue {

	background: #3E4095;
/* Old browsers */
background: -moz-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
/* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,
#3E4095), color-stop(100%,
	#124f6b));
/* Chrome, Safari4+ */
background: -webkit-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
/* Chrome10+, Safari5.1+ */
background: -o-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
/* Opera 11.10+ */
background: -ms-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
/* IE10+ */
background: linear-gradient(to bottom,
#3E4095 0%,
	#124f6b 100%);
/* W3C */

	box-shadow: 0px 2px 0px 0px rgba(9, 59, 82, 1);
	-o-box-shadow: 0px 2px 0px 0px rgba(9, 59, 82, 1);
	-moz-box-shadow: 0px 2px 0px 0px rgba(9, 59, 82, 1);
	-webkit-box-shadow: 0px 2px 0px 0px rgba(9, 59, 82, 1);
	-ms-box-shadow: 0px 2px 0px 0px rgba(9, 59, 82, 1);
	color: #ccc;
}
.btn-blue:hover {
	color: white;
}
.btn-lg {
	line-height: 60px;
	padding: 0 30px
}
.btn-sm {
	line-height: 40px;
	padding: 0 20px
}
.btn-xs {
	line-height: 30px;
	padding: 0 15px;
}
.header2 {
	margin-top: 60px;
}
.header2 hr {
	margin-left: 0;
}

#scrollTop {
	display: none;
	position: fixed;
	bottom: 0 ;
	right: 30px;
	width: 50px;
	background: white;
	text-align: center;
	-webkit-border-top-left-radius: 15px;
-webkit-border-top-right-radius: 15px;
-moz-border-radius-topleft: 15px;
-moz-border-radius-topright: 15px;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
background: #3E4095;
}
#scrollTop .fa{
	font-size: 30px;	
	color: #fff;
	outline: none;
	text-decoration: none;
}

/*Accordion**/
.panel-collapse .panel-body {
	
	line-height: 1.5em;
}
#accordion .panel-default > .panel-heading {
	border: none;
	background: none;
	color: #3E4095;
}
#accordion .panel {
	padding: 0;
}
#accordion .panel > .panel-heading {
	padding: 0;
}
#accordion .panel > .panel-heading h4 .fa {
	font-size: 1.2em;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	position: relative;
	display: inline-block;
	width: 60px;
	height: 60px;
	border: 1px solid #ccc;
	top: 4px;
	text-align: center;
	line-height: 57px;
	padding: 0 0px 0 4px;
	margin-right: 20px;
	color: #3E4095;
	border: 2px solid #3E4095;
	margin-bottom: 0;
}
#accordion .panel > .panel-heading h4 .collapsed .fa {
	color: #ccc;
	border: 2px solid #ccc;
	margin-bottom: 50px;
}
#accordion .panel > .panel-heading h4 {
	color: #3E4095;
	font-weight: bold;
}
#accordion .panel > .panel-collapse .panel-body {
	background: transparent url('../images/dot.png') repeat-y left center;
	margin: 15px 0 15px 28px;
	color: #838383;
	padding: 0 0 0 55px;
}
#accordion .panel > .panel-heading h4 .collapsed .fa:after {
	background: transparent url('../images/dot-2.png') repeat-y left center;
	color: #838383;
	content: " ";
	height: 30px;
	left: 25px;
	position: absolute;
	top: 73px;
	width: 100%;
}
#accordion .panel > .panel-heading h4 .collapsed .last:after {
	background: none;
}
/*Graph */
.statistics {
	max-width: 1366px;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
}
.graph .itemg {
	margin-bottom: 25px;
}
.graph .itemg .label {
	position: relative;
	display: block;
	text-align: left;
	padding: 0 0 14px 0;
	font-size: 1em;
	font-weight: normal;
}
.graph .itemg .bar {
	width: 100%;
	height: 10px;
	background: #fff;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
.graph .itemg .label .value {
	position: absolute;
	right: 0;
	top: 0px;
}
.graph .itemg .bar .fillzone {
	height: 100%;
	background: #3E4095;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
/*09. Accordion1 */
.accordion .panel-heading {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 30px;
	background: white;
	padding: 20px 30px;
}
.accordion .panel {
	border: none;
	background: none;
	box-shadow: none;
}
.accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border: none;
}
.accordion  .arrow-toggle .icon-arrow-down, .accordion .arrow-toggle.collapsed .icon-arrow-up {
	display: inline-block;
}
.accordion .arrow-toggle.collapsed .icon-arrow-down, .accordion .arrow-toggle .icon-arrow-up {
	display: none;
	position: absolute;
	right: 0px;
	font-size: 2em;
	margin: -6px 0 0;
}
.accordion .arrow-toggle {
	display: block;
	width: 100%;
	position: relative;
}
.accordion .arrow-toggle .icon-arrow-down, .accordion .arrow-toggle .icon-arrow-down {
	position: absolute;
	right: 0px;
	font-size: 2em;
	color: #eb4268;
	margin: -6px 0 0;
}
.accordion .arrow-toggle.collapsed {
	color: #112641;
}
/* Accordion 2 */
.accordion2 .panel {
	border: 1px solid #ccc;
}
/*Tabs*/
.tabs {
	background: #0e4d6c;
	overflow: hidden;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border: 1px solid #e7e7e7;
	border-bottom: 0;
	background-clip: padding-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	color: white;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
	border: none;
	box-shadow: none;
	text-shadow: none;
	color: #3E4095;
}
.tabs li a {
	font-size: 1.3em;
	padding: 15px 20px;
	border: none;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	border: none;
	background: #0e4d6c;
	color: #fff;
}
.tabs li a:hover {
	background: #115c81;
}
.goTabs li.active  a, .goTabs li.active  a:hover, .goTabs li.active  a:active, .goTabs li.active  a:focus {
	color: #b9193d;
}
.tabs  > li > a {
	margin-right: inherit;
}
.tabs li  a:hover {
	border: none;
}
.tabs-panel {
	background: #fff;
	padding: 15px 15px 30px 15px;
}
.tabs .tab-pane {
	background: white;
}
.tabs-content .tab-pane {
	background: white;
	padding: 15px;
	border: 1px solid #e7e7e7;
	border-top: none;
}
/* list 1*/
.list1 {
	padding: 0;
}
.list li {
}
.list {
	padding: 0;
	margin: 0;
}
.list li {
	position: relative;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.list li {
	font-size: 1.2em;
	color: #0e1f30;
	line-height: 2.3em;
	border-bottom: 1px dashed #e0e0e0;
	padding-bottom: 3px;
}
.list li:last-child {
	border-bottom: none;
}
.list li a {
	padding-left: 25px;
	font-size: 1em;
	color: #0e1f30;
	line-height: 2.3em;
	text-decoration: none;
	font-family: Lato;
	letter-spacing: 0;
}
.list li a:hover:after {
	color: #fa6c0c;
}
.list li a:after {
	position: absolute;
	font-family: 'FontAwesome';
	top: 0;
	left: 0px;
	content: "\f046";
	color: #9ca0a4;
}
.list2 li a:after {
	content: "\f054";
}
.list3 li a:after {
	content: "\f00c";
}
.list4 li a:after {
	content: "\f138";
}
.lists .header2 {
	padding: 0 15px;
}
.lists .header2 h3 {
	font-size: 1.4em;
}
.lists > div {
	margin-bottom: 40px;
}
/** *Top Menu * */
.top-menu {
	background: #BE8D48;
}
.top-menu  a {
	color: #fff;
}
.top-menu .container .navbar .nav  a:hover {
	background: none;
	color: #3E4095;
}
.top-menu .container .navbar {
	margin: 0;
}
.top-menu .container .navbar .navbar-nav > li {
	padding: 0;
	line-height: 48px;
	height: 48px;
}
.top-menu .container .navbar .navbar-right > li > a {
	padding: 0;
	display: inline-block;
	width: 33px;
	height: 33px;
	line-height: 28px;
	text-align: center;
	margin-left: 10px;
	border: 2px solid #FFFFFF;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.top-menu .container .navbar .navbar-right > li > a:hover {
	border: 2px solid #3E4095;
}
.top-menu .container .navbar .navbar-left > li {
	border-left: 1px solid #ffffff;
	line-height: 47px;
}
.top-menu .container .navbar .navbar-left > li.last {
	border-right: 1px solid #3E4095;
}
/*Main Menu */
#mainMenu {
	border: none;
	background: none;
	top: 0;
	background: white;
	margin: 25px auto;
}
#mainMenu  .navbar-brand {
	height: auto;
}
#mainMenu .navbar-collapse {
	padding: 20px 0 0 0;
}
.navbar-nav > li > a {
	padding: 12px 17px;
}
#mainMenu .navbar-collapse .navbar-nav li a {
	color: #3E4095;
	background: none;
	font-family: Roboto;
	font-weight: normal;
	font-size: 14px;
	color: #968d99;
}
#mainMenu .navbar-collapse li.active a {
	font-weight: bold;
	color: #3E4095;
}
#mainMenu .navbar-collapse li a:hover,
#mainMenu .navbar-collapse li a:active {
	color:  #3E4095;
}
#mainMenu .navbar-collapse .button1 {
	line-height: 35px;
	font-size: 18px;
	width: 36px;
	height: 36px;
	display: inline-block;
	border: none;
	background: #d6274f;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	color: white;
	position: absolute;
	right: 13px;
	top: 0;
	outline: none;
}
#mainMenu .navbar-collapse .form-control {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	width: 30px;
}
#mainMenu .navbar-collapse .button1:hover {
	background: #d6274f;
}
/*slider */
.main-slider .carousel-indicators li {
	border: none;
	background: #efefef;
}
.main-slider .carousel-indicators .active {
	background: #f37456;
}
.main-slider .carousel-inner {
	background: #3E4095;
}
.main-slider .carousel-inner .item h2 {
	font-size: 3em;
	font-family: Lato;
	margin-bottom: 3%;
}
.main-slider .carousel-inner .item p {
	font-size: 1.5em;
	margin-bottom: 3%;
}
.main-slider .carousel-inner .item img {
	-moz-opacity: 0.30;
	-khtml-opacity: 0.30;
	opacity: 0.30;


	filter: alpha(opacity=30);
}
.main-slider .carousel-inner .item .call2action {
	margin-bottom: 5%;
}
.main-slider .carousel-inner .item .call2action a {
	font-size: 14px;
	background: #BE8D48;
background: -moz-linear-gradient(top,
#BE8D48 0%,
	#BE8D48 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,
#BE8D48), color-stop(100%,
	#BE8D48));
background: -webkit-linear-gradient(top,
#BE8D48 0%,
	#BE8D48 100%);
background: -o-linear-gradient(top,
#BE8D48 0%,
	#BE8D48 100%);
background: -ms-linear-gradient(top,
#BE8D48 0%,
	#BE8D48 100%);
background: linear-gradient(to bottom,
#BE8D48 0%,
	#BE8D48 100%);

	color: white;
	text-shadow: none;
	padding: 0px 30px;
	line-height: 45px;
	color: white;
	text-decoration: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	display: inline-block;
}
.main-slider .carousel-inner .item .call2action a:hover, .carousel-inner .item .call2action a:active {
	background: #8E734C;
	
background: -moz-linear-gradient(top,#8E734C 0%,	#8E734C 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,
#8E734C), color-stop(100%,
	#8E734C));
background: -webkit-linear-gradient(top,
#8E734C 0%,
	#8E734C 100%);
background: -o-linear-gradient(top,
#8E734C 0%,
	#8E734C 100%);
background: -ms-linear-gradient(top,
#8E734C 0%,
	#8E734C 100%);
background: linear-gradient(to bottom,
#8E734C 0%,
	#8E734C 100%);
	
}
.main-slider .carousel-indicators {
	bottom: 0;
}
/*seconda slider */
.slide2 .carousel-caption {
	left: 12%;
	width: 570px;
	top: 12%;
	text-align: left;
}
.slide2 .toRight {
	right: 12%;
	left: auto;
	text-align: right;
}
.slide2   .carousel-caption h2 {
	font-weight: bold;
	font-family: Lato;
	font-size: 3em;
	margin: 0;
}
.slide2   .carousel-caption h3 {
	font-size: 2.8em;
	font-weight: 100;
	margin-top: 0;
}
.slide2   .carousel-caption p {
	font-weight: 100;
	font-size: 1.2em;
	margin-top: 25px;
}
.slide2 .carousel-caption .call2action {
	margin-top: 35px;
}
.slide2 .carousel-caption .call2action .btn {
	margin: 0 5px;
}
/* First Heading */
.heading1 {
	margin: 60px auto;
}
.heading1 h1 {
	text-align: center;
	font-weight: bold;
	color: #3E4095;
	font-size: 2em;
}
.heading1 hr {
	height: 6px;
	border: none;
	display: block;
	width: 30px;
	background: #3E4095;
	margin: 15px auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.heading1 > p {
	max-width: 545px;
	margin: 0 auto;
	text-align: center;
}
/*  Block 1  */
.block1 {
}
.block1 .wrapper {
	text-align: center;
	height:420px;
}
.fa-wrapper:before {
	content: " ";
	background: #ffffff;
background: -moz-linear-gradient(-45deg,
#ffffff 53%,
	#e9e9e9 100%);
background: -webkit-gradient(linear, left top, right bottom, color-stop(53%,
#ffffff), color-stop(100%,
	#e9e9e9));
background: -webkit-linear-gradient(-45deg,
#ffffff 53%,
	#e9e9e9 100%);
background: -o-linear-gradient(-45deg,
#ffffff 53%,
	#e9e9e9 100%);
background: -ms-linear-gradient(-45deg, #ffffff 53%,	#e9e9e9 100%);
background: linear-gradient(135deg,
#ffffff 53%,
	#e9e9e9 100%);

	height: 255px;
	left: 17.6%;
	position: absolute;
	top: -127.5px;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 255px;
	z-index: -1;
}
.fa-wrapper {
	height: 171px;
	overflow: hidden;
}
.block1 .wrapper .fa-wrapper .icon-fa1 {
	display: inline-block;
	font-size: 6em;
	position: relative;
	top: 35px;
	color: #3E4095;
}
.block1 h2 {
	margin: 12px auto 22px auto;
	color: #3E4095;
}
.block1 .active h2, .block1 .active .fa-wrapper .icon-fa1 {
	color: #3E4095;
}
.block1 p {
	max-width: 260px;
	margin: 0 auto;
}
.dots-3 {
	height: 50px;
}
.dots-3 p {
	padding: 0;
	line-height: 11px;
	font-size: 30px;
	font-family: Lato;
	font-weight: bold;
	text-align: center;
	color: #e7e7e7;
}
.block1 .btn-off {
	position: relative;
	top: 20px;
}
/*Promo Section*/
#from-the-blogP{clear: both;}
.promo {
	background-image: url('../images/back.jpg');
	margin-top: 125px;
}
.promo  .middleColumn {
	position: absolute;
	width: 56%;

	
	margin: 30px 0 0 22%;
	left: 0;
}
.promo  .middleColumn  img {
	width: 100%;
}
.promo .container .col-sm-3 {
	width: 28%;
}
.promo .container .row {
	margin: 55px 0 100px;
}
.promo .container .item {
	margin-top: 50px;
}
.promo .container .item h2 {
	font-size: 18px;
	font-weight: bold;
	color: #3E4095;
}
.promo .container .item p {
	color: #5e5a53;
}
.promo .container .item h2 span {
	font-size: 24px;
	padding-right: 10px;
	position: relative;
	top: 3px;
	color: #3E4095;
}
.promo .container  hr {
	height: 6px;
	border: none;
	display: block;
	width: 30px;
	background: #3E4095;
	margin: 20px 0 0 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
#recent_from_the_blog {
	margin-top: 12%;
}
#recent_from_the_blog .item {
	overflow: hidden;
	border: 1px solid #e7e7e7;
	max-width: 360px;
	margin: 0 auto;
	position: relative;
	height: 420px;
}
#recent_from_the_blog .item h2 {
	font-size: 18px;
	color: #3E4095;
	padding: 0 15px;
}
#recent_from_the_blog .item p {
	padding: 0 15px;
}
#recent_from_the_blog .item .dos3-2 {
	font-size: 30px;
	text-align: center;
	color: #3E4095;
	padding-bottom: 15px;
}
#recent_from_the_blog .item .dos3-2 a:hover {
	color: #BE8D48;
	text-decoration: none;
}
#recent_from_the_blog .item .image {
}
#recent_from_the_blog .item .image {
	height: 220px;
	overflow: hidden;
	width: 360px;
	margin: 10px auto 0;
}
#recent_from_the_blog .item .image:before {
	background: #3E4095;
	height: 10px;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	content: " ";
	width: 100%;
	left: 0;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
#recent_from_the_blog .item .image img {
	-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
#recent_from_the_blog .mouseover  .image img {
	width: 120%;
	margin-left: -10%;
	margin-top: -10%;
	display: block;
}
#recent_from_the_blog .item img {
	width: 100%;
}
#recent_from_the_blog .item .info {
	color: #25a199;
	margin-top: 15px;
	padding: 0 15px;
}
#recent_from_the_blog .item .info span {
	padding-right: 8px;
}
#recent_from_the_blog .item .info span.comments {
	margin-left: 40px;
}
#recent_from_the_blog .btnrow {
	margin: 50px 0;
	text-align: center;
}
#recent_from_the_blog  .btn {
	background: none;
	color: #3E4095;
}
#recent_from_the_blog  .btn:hover {
	border-color: #BE8D48;
	color: #BE8D48;
}
/*  Parallax */
.parallax {
	min-height: 300px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	overflow: hidden;
	position: relative;
}
.parallax, .parallax .overlay {
	height: 300px;
}
.parallax hr {
	height: 6px;
	border: none;
	display: block;
	width: 30px;
	background: #BE8D48;
	margin: 15px auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.parallax .overlay {
	background: #112641;
	opacity: 0.84;
	filter: alpha(opacity=84);
	width: 100%;
	position: absolute;
	z-index: 0;
}
.parallax .container {
	position: relative;
	z-index: 1;
}
.parallax h2 {
	color: white;
	font-size: 2em;
	text-align: center;
	padding-top: 40px;
}
.parallax  p {
	color: #fff;
	text-align: center;
	font-size: 1.2em;
	line-height: 1.8em;
}
/*intro 2*/
#intro2  .container {
	position: relative;
}
#intro2 .toRightCol {
	position: absolute;
	bottom: 0;
	right: 0;
}
/*newsletter*/
.newsletter {
	background: #BE8D48;
	color: #fff;
	padding: 20px 0 25px 0;
}
.newsletter h1 {
	font-size: 1.8em;
	margin-bottom: 8px;
}
.newsletter .toRightCol .form-control {
	width: 260px;
	float: left;
	display: inline-block;
	line-height: 35px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.newsletter .toRightCol {
	width: 385px;
	padding: 0;
}
.newsletter .toRightCol .btn-blue {
	display: inline-block;
	line-height: 32px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #3E4095;
	background: -moz-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,
#3E4095), color-stop(100%,
	#124f6b));
background: -webkit-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
background: -o-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
background: -ms-linear-gradient(top,
#3E4095 0%,
	#124f6b 100%);
background: linear-gradient(to bottom,
#3E4095 0%,
	#124f6b 100%);
	
	color: white;
	float: right;
	padding: 0;
	width: 110px;
}
.newsletter .toRightCol .btn-blue:hover {
	background: #3E4095;
background: -moz-linear-gradient(top,
#3E4095 0%,
	#3e3870 100%);
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,
#3E4095), color-stop(100%,
	#3e3870));
background: -webkit-linear-gradient(top,
#3E4095 0%,
	#3e3870 100%);
background: -o-linear-gradient(top,
#3E4095 0%,
	#3e3870 100%);
background: -ms-linear-gradient(top,
#3E4095 0%,
	#3e3870 100%);
background: linear-gradient(to bottom,
#3E4095 0%,
	#3e3870 100%);

}
.newsletter .toRightCol form {
	margin-top: 30px;
}
/* our features */
.our-features {
	background: #3E4095;
}
.our-features .heading1 h1, .our-features .heading1 p {
	color: #fff;
}
/* fullColumnImages */
.fullColumnImage .row, .fullColumnImage, .fullColumnImage .row .wrapper {
	padding: 0;
	border: none;
	margin: 0;
}
.fullColumnImage .row .wrapper {
	border-right: 1px solid white;
}
.fullColumnImage  .row .item {
	position: relative;
	overflow: hidden;
}
.fullColumnImage  .row .item img {
	width: 100%;
}
.fullColumnImage  .row .item .overlay {
	width: 100%;
	height: 100%;
	background: rgba(190, 141, 72, 0.58);
	position: absolute;
	top: -100%;
	left: 0;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition:         all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.fullColumnImage  .row .mouseover .overlay {
	top: 0;
}
.fullColumnImage  .row .item .overlay h3 {
	font-size: 1.2em;
	font-size: 18px;
	text-align: center;
	margin-top: 25%;
	margin-bottom: 20px;
	color: white;
}
.fullColumnImage  .row .item .overlay h3 a, .fullColumnImage  .row .item .overlay p a {
	color: white;
}
.fullColumnImage  .row .item .overlay p {
	color: white;
	text-align: center;
	max-width: 250px;
	margin: 0 auto;
}
.fullColumnImage  .row .item .icon {
	text-align: center;
	font-size: 1.8em;
	color: white;
	margin-top: 15px;
}
#what-students-say {
	background:  transparent url(../images/background-wall.jpg);
}
#depoimentos
{
	background:  transparent url(../images/background-wall.jpg);
}

.what-students-say {
	padding-bottom: 40px;
	overflow: hidden;
}
.what-students-say .avatar a img {
	-webkit-border-radius: 45px;
	-moz-border-radius: 45px;
	border-radius: 45px;
	border: 2px solid #3E4095;
}
.what-students-say h1 {
	font-size: 1.7em;
	margin: 55px 0 0 0;
}
.what-students-say .row > div > hr {
	margin: 20px 0 50px 0;
}
.what-students-say .review {
	margin-left: 30px;
	background: #3E4095;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 15px;
}
.what-students-say .mouseover {
	background: #BE8D48;
}
.what-students-say  .mouseover .avatar a img {
	border: 2px solid #BE8D48;
}
.what-students-say .items .rows {
	display: none;
}
.what-students-say .items .i0 {
	display: block;
}
.what-students-say .items p {
	padding: 0 20px;
}
.what-students-say .items.what-students-say .pagination {
	clear: both;
	width: 100%;
}
.what-students-say .pagination ul, .what-students-say .pagination {
	display: block;
	text-align: center;
	width: 100%;
	margin: 0 auto;
	clear: both;
	padding-top: 20px;
}
.what-students-say .pagination ul li {
	display: inline-block;
	margin: 0 5px;
}
.what-students-say .pagination ul li a {
	text-decoration: none;
}
.what-students-say .info a {
	color: #bee4e1;
	position: relative;
}
.what-students-say .mouseover .info a {
	color: #fbcdc2;
}
.what-students-say  .info span {
	position: absolute;
	right: 40px;
}
/* Meet our instructors*/
#instructors {
	text-align: center;
	min-height: 380px;
}
.meet-our-instructors {
	position: relative;
}
.meet-our-instructors .items {
	width: 900px;
	margin: 0 auto;
}
.meet-our-instructors .container {
}
.meet-our-instructors .items  .image {
	-webkit-border-radius: 135px;
	-moz-border-radius: 135px;
	border-radius: 135px;
	border: 6px solid #e8e8e8;
	overflow: hidden;
	width: 200px;
	height: 200px;
	position: relative;
}
.meet-our-instructors .items  .image img {
	width: 100%;
}
.meet-our-instructors .items  .item {
	margin: 0 17px;
	width: 200px;
}
.meet-our-instructors .items  .item .overlay {
	width: 100%;
	height: 100%;
	background: rgba(37,163,155,.7);
	position: absolute;
	top: -100%;
	left: 0;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition:         all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	text-align: center;
	font-size: 20px;
	line-height: 200px;
	color: white;
}
.meet-our-instructors .items .item .overlay a {
	color: white;
	display: inline-block;
	width: 30px;
	text-align: center;
	text-decoration: none;
}
.meet-our-instructors .items  .item .overlay a:hover {
	color: #ff4800;
}
.meet-our-instructors .items  .mouseover .overlay {
	top: 0;
}
/**carousel sliderX */
.meet-our-instructors {
	margin-top: 40px;
}
.meet-our-instructors .navigation .left, .meet-our-instructors .navigation .right {
	position: absolute;
	-webkit-border-radius: 135px;
	-moz-border-radius: 135px;
	border-radius: 135px;
	border: 6px solid #fff;
	display: inline-block;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 37px;
	font-size: 30px;
	color: #e8e8e8;
	text-decoration: none;
	top: 50%;
	transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
}
.meet-our-instructors .navigation .left:hover, .meet-our-instructors .navigation .right:hover {
	border: 6px solid #3E4095;
	color: #3E4095;
}
.meet-our-instructors .navigation .left {
	left: 0;
}
.meet-our-instructors .navigation .right {
	right: 0;
	text-align: center;
}
.meet-our-instructors .navigation .left:after {
	content: "...";
	color: red;
	width: 60px;
	left: 36px;
	height: 30px;
	position: absolute;
	top: -7px;
	z-index: -1;
	font-size: 30px;
	font-weight: bold;
	color: #e8e8e8;
}
.meet-our-instructors .navigation .right:after {
	content: "...";
	color: white;
	width: 60px;
	left: -60px;
	height: 30px;
	position: absolute;
	top: -7px;
	z-index: -1;
	font-size: 30px;
	font-weight: bold;
	color: #e8e8e8;
}
.meet-our-instructors .item {
	text-align: center;
}
/*Contact section*/
.contact {
	margin-bottom: 80px;
}
.contact > .row .map {
	position: relative;
}
.contact > .row .map img {
	width: 100%;
}
.contact > .row .map .marker {
	position: absolute;
	font-size: 50px;
}
.contact > .row .map .marker .fa-map-marker {
	text-decoration: none;
	color: #3E4095;
}
.contact > .row .map .marker .fa-map-marker:hover, .contact > .row .map .marker .mouseover {
	color: #BE8D48;
}
.contact > .row .map .popup {
	position: relative;
	width: 195px;
	height: 85px;
	margin-top: -88%;
	display: none;
}
.contact > .row .map .popup .popup-wrapper {
	position: absolute;
	transform: translate(-47%, 0);
	-o-transform: translate(-47%, 0);
	-moz-transform: translate(-47%, 0);
	-ms-transform: translate(-47%, 0);
	-webkit-transform: translate(-47%, 0);
	
	background: white;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
	z-index: 0;
	padding-bottom: 0;
}
.contact > .row .map .popup .popup-wrapper p {
	background: #fff;
	padding-bottom: 10px;
	height: 55px;
	position: relative;
	top: 10px;
	font-size: 14px;
	padding: 0px 10px 0 10px;
}
.contact > .row .map .popup .popup-wrapper:before {
	width: 16px;
	height: 16px;
	position: absolute;
	bottom: -8px;
	left: 50%;
	transform: translate(-50%, 0);
	transform: rotate(45deg);
	-o-transform: translate(-50%, 0);
	-o-transform: rotate(45deg);
	-ms-transform: translate(-50%, 0);
	-ms-transform: rotate(45deg);
	-moz-transform: translate(-50%, 0);
	-moz-transform: rotate(45deg);
	-webkit-transform: translate(-50%, 0);
	-webkit-transform: rotate(45deg);	
	content: " ";
	background: white;
	z-index: -1;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
}
.contact > .row .map .popup .popup-wrapper .title {
	font-size: 14px;
	background: #BE8D48;
	color: white;
	text-align: center;
	line-height: 27px;
	-webkit-border-top-left-radius: 2px;
	-webkit-border-top-right-radius: 2px;
	-moz-border-radius-topleft: 2px;
	-moz-border-radius-topright: 2px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}
.contact form h1 {
	font-size: 1.7em;
}
.contact form hr {
	margin-left: 0;
}
.contact .form-group {
	position: relative;
}
.contact .form-group .fa {
	font-size: 1.2em;
	line-height: 2em;
	padding: 3px 0 0 12px;
	position: absolute;
}
.contact .form-control {
	height: auto;
	line-height: 2em;
	padding: 6px 12px 6px 40px;
}
/* How to learn german online */
.how_to_learn_german_online {
	background: transparent url(../images/1600x521-blure.jpg) no-repeat center top;
	padding: 0;
}
.how_to_learn_german_online .overlay {
	padding: 80px 0;
	margin: 0;
	background: rgba(10,64,94,.8)
}
.how_to_learn_german_online .video iframe {
	width: 100%;
	border: none;
}
.how_to_learn_german_online .morevideosList {
	display: none;
}
.how_to_learn_german_online .header2, .how_to_learn_german_online .header2 h1 {
	margin-top: 0px;
	color: white;
}
.how_to_learn_german_online .header2 h1 {
	color: white;
	font-size: 1.3em;
}
.how_to_learn_german_online .header2 hr {
	background: #BE8D48;
}
.how_to_learn_german_online .info p {
	height: 170px;
}
.how_to_learn_german_online .info .call2action {
	margin-top: 34px;
}
.how_to_learn_german_online .info .call2action .btn, .how_to_learn_german_online .action-button .btn {
	line-height: 52px;
	height: auto;
	display: inline-block;
}
.how_to_learn_german_online  .videos a {
	overflow: hidden;
	display: block;
	position: relative;
	line-height: 115px;
	width: 169px;
	height: 115px;
}
.how_to_learn_german_online  .videos a span {
	background: rgba(37,163,155,0.25);
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 115px;
	display: block;
	left: -100%;
	top: 0;
	font-size: 2em;
	color: white;
	margin: 0;
	-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.how_to_learn_german_online  .videos a:hover span {
	display: block;
	top: -0;
	left: 0%;
}
/*packages*/
.onepackage {
	border: 1px solid #e7e7e7;
}
.packages * {
	-webkit-transition: all 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
	transition:         all 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.onepackage .fa-wrapper:before {
	content: " ";
	height: 192px;
	left: 17.6%;
	position: absolute;
	top: -97.5px;
	transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 192px;
}
.onepackage .fa-wrapper {
	text-align: center;
	height: 130px;
}
.icon-fa2 {
	display: inline-block;
	font-size: 4em;
	position: relative;
	top: 25px;
	color: #3E4095;
}
.onepackage  h2 {
	font-size: 1.6em;
	text-align: center;
	margin: 10px auto;
	color: #3E4095;
}
.onepackage .price {
	border-top: 1px dashed #e7e7e7;
	border-bottom: 1px dashed #e7e7e7;
	padding: 10px 0;
}
.onepackage .price h3 {
	text-align: center;
	font-size: 3.5em;
	font-weight: bold;
	margin: 5px 0 0 0;
	color: #3E4095;
}
.onepackage .price p {
	text-align: center;
	font-size: 1.2em;
	margin: 0 auto 5px auto;
	color: #838383;
}
.onepackage ul > li:before {
	content: "";
	font-family: fontAwesome;
	position: absolute;
	left: 45px;
	color: #3E4095;
}
.onepackage ul {
	padding: 0;
	margin: 25px 0 0 40px;
}
.onepackage ul > li {
	list-style-type: none;
	padding: 0 0 0  25px;
	margin: 0;
	color: #838383;
	line-height: 2.5em;
	font-size: 1.2em;
}
.onepackage .points3 {
	text-align: center;
	font-size: 3em;
	color: #bcbcbc;
	line-height: .3em;
	height: 35px;
}
.onepackage .package-btn {
	text-align: center;
	position: relative;
	top: 27px;
}
.packages   .fa-wrapper-select {
	margin: 0;
	padding: 0;
}
.packages  .active .fa-wrapper-select, .packages  .mouseover .fa-wrapper-select {
	background: #BE8D48;
	color: #BE8D48;
}
.packages  .active .fa-wrapper-select .price, .packages  .mouseover .fa-wrapper-select .price {
	border: none;
}
.packages  .active .fa-wrapper-select .price h3, .packages  .active .fa-wrapper-select .price p  , .packages  .active .fa-wrapper span, .packages  .active h2, .packages  .mouseover .fa-wrapper-select .price h3, .packages  .mouseover .fa-wrapper-select .price p  , .packages  .mouseover .fa-wrapper span, .packages  .mouseover h2 {
	color: #fff;
}
.packages  .active ul li:before, .packages  .mouseover ul li:before {
	color: #0e4d6c;
}
.packages  .active  .package-btn .btn, .packages  .mouseover  .package-btn .btn {
	border: 2px solid #BE8D48;
}
/*Clients */
.clients {
	margin-top: 80px;
	background: #BE8D48;
	padding: 50px 0;
}
.clients .clients-logos {
	width: 80%;
	margin: 0 auto;
}
.clients .wrapper {
	padding: 0;
	position: relative;
}
.clients .wrapper .logoWrapper {
	position: relative;
	margin: 0 50px;
	overflow: hidden;
}
.clients .wrapper .logos {
	position: relative;
}
.clients .wrapper .logos a {
	display: inline-block;
	margin: 0 15px;
}
.clients .navigation {
	position: relative;
}
.clients .navigation .fa {
	font-size: 3em;
	text-decoration: none;
	color: #fff;
	position: absolute;
	top: 50%;
	transform: translate(0,50%);
	-o-transform: translate(0,50%);
	-ms-transform: translate(0,50%);
	-moz-transform: translate(0,50%);
	-webkit-transform: translate(0,50%);
	
}
.clients .navigation .fa:hover {
	color: #c67002;
}
.clients .navigation .right {
	right: 0;
}
/** Start the footer */
/*Footer Light*/
.footer-light {
	background: #3E4095;
	padding-bottom: 40px;
}
.footer-light h3, .dark-footer h3 {
	color: #fff;
}
.footer-light hr, .dark-footer hr {
	background: #BE8D48;
}
.footer-light p, .dark-footer p {
	color: #b8ccd6;
}
.footer-light .imagerighttext {
	clear: both;
	overflow: hidden;
	margin-bottom: 15px;
	max-width: 365px;
	margin-left:  auto;
	margin-right: auto;
}
.footer-light .imagerighttext .img {
	float: left;
	margin-top: 5px;
}
.footer-light .imagerighttext .text {
	clear: right;
	width: 280px;
	float: right;
}
.footer-light .imagerighttext .text h2 {
	color: white;
	font-size: 1.2em;
	margin: 0;
}
.footer-light .imagerighttext .text p {
	margin: 2px 0;
	font-size: .9em;
	line-height: 1.45em;
}
.footer-light  a {
	color: #6f92a2;
	text-decoration: none;
}
.footer-light a:hover, .footer-light  a:active {
	color: #fff;
}
.footer-light .imagerighttext .links a span.fa {
	padding-left: 20px;
}
.footer-light .info .item {
	color: #adc8d6;
	line-height: 2em;
}
.footer-light .info .item  span {
	color: #BE8D48;
	padding-right: 10px;
	font-size: 1.2em;
}
.footer-light .info .item  strong {
	color: #fff;
	padding-right: 3px;
}
.footer-light .fl-wrapper {
	min-height: 300px;
}
.footer-light .link {
	clear: both;
	margin-top: 25px;
}
a.orange {
	color: #BE8D48;
}
.footer-light .link a.orange span {
	padding-left: 5px;
}
.footer-light .fl-wrapper {
	min-height: 180px;
}
/*Dark footer */
.dark-footer {
	background: #09394f;
	padding: 40px 0;
}
.dark-footer ul.dashedList {
	padding: 0;
}
.dark-footer ul.dashedList li {
	list-style-type: none;
	border-bottom: 1px dashed #395e70;
	color: #adc8d6;
	line-height: 2em;
	padding: 5px 5px 10px 5px;
}
.dark-footer ul.dashedList li:last-child {
	border: none;
}
.dark-footer ul.dashedList, .dark-footer ul.dashedList a {
	color: #adc8d6;
}
.dark-footer  hr {
	margin-left: 0;
}
/* Footer Copywrite*/
.copywritesection {
	padding: 30px 0;
	background: #252761;
	color: #637b86;
}
.copywritesection .sIcons {
	text-align: right;
	float: right;
}
.copywritesection  .left {
	float: left;
}
.copywritesection .sIcons a {
	display: inline-block;
	line-height: 30px;
	height: 30px;
	width: 30px;
	color: #637b86;
	border: 1px solid #637b86;
	text-align: center;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	margin-right: 10px;
	text-decoration: none;
	-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.copywritesection .sIcons a:hover {
	color: white;
	border: 1px solid #fff;
}
/*Homepage 2*/
.fromtheblog2 .items {
	margin-top: 20px;
}
.fromtheblog2 .items .item {
	padding-top: 6px;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px solid #e7e7e7;
	background: #cfd3d3;
	border-top: none;
	margin-bottom: 25px;
	-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.fromtheblog2 .items .selected, .fromtheblog2 .items .item:hover {
	background: #23527c;
}
.fromtheblog2 .items .item .wrapper {
	background: white;
	overflow: hidden;
}
.fromtheblog2 .items .item img {
	display: inline-block;
	margin: 0 15px 0 0;
}
.fromtheblog2 .items .item .wrapper .info {
	color: #23527c;
	margin-top: 30px;
}
.fromtheblog2 .items .item .wrapper .info  span {
	padding-right: 15px;
}
.fromtheblog2 .items .item .wrapper .info  span.fa {
	padding-right: 5px;
}
.fromtheblog2 .items .item .wrapper  h2 {
	font-size: 1.4em;
}
.fromtheblog2 .items .item .wrapper  h2, .fromtheblog2 .items .item .wrapper  h2 a {
	color: #3E4095;
}
.fromtheblog2 .items .item .wrapper  p, .fromtheblog2 .items .item .wrapper  p a {
	color: #838383;
}
.fromtheblog2 .items .item .wrapper  p {

}
.fromtheblog2 .moreItems {
	margin: 20px 0 40px 0;
	text-align: center;
}
.dots3 {
	color: #3E4095;
	font-weight: bold;
	font-size: 3em;
	letter-spacing: 1px;
	line-height: 0.8em;
}
/* Parallax  */
.formP {
	overflow: hidden;
}
.formP .col {
	float: left;
	margin: 10px;
}
.formP .col1 {
	width: 220px;
}
.formP .col2 {
	width: 200px;
}
.formP .form-control, .formP .col .btn {
	line-height: 40px;
	height: 40px;
}
.formP .col .btn {
	width: 100%;
}
/*Gallery*/
.galleryFlst2 .row .items  > div a {
	position: relative;
	display: block;
	padding: 0;
	margin: 0;
	width: 100%;
	overflow: hidden;
}
.galleryFlst2 .row .items  > div a  span {
	display: block;
}
.galleryFlst2 .row .items  > div a > span {
	display: block;
	position: absolute;
	top: 100%;
	left: 0%;
	width: 100%;
	margin: 0;
	text-align: center;
	height: 100%;
	-webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.galleryFlst2 .row .items  > div a > img {
	width: 100%;
}
.galleryFlst2 .row .items  > div a:hover > span.info {
	background: rgba(242,92,56,0.7);
	top: 0%;
}
.galleryFlst2 .row .items  > div a > span .heart {
	text-align: left;
	margin: 15px 0 15px 15px;
	color: #fff;
}
.galleryFlst2 .row .items  > div a > span .heart .fa {
	display: inline-block;
	padding-right: 5px;
}
.galleryFlst2 .row .items  > div a > span .icon {
	text-align: center;
	font-size: 4em;
	color: white;
	margin: 20px 0;
}
.galleryFlst2 .row .items  > div a > span .title {
	font-size: 1.3em;
	color: #fff;
	font-weight: bold;
	margin: 10px 20px;
	line-height: 1.2em;
}
.galleryFlst2 .row .items  > div a > span .description {
	font-size: 1em;
	color: #fff;
	margin: 10px 20px;
	line-height: 1.2em;
}
.galleryFlst2 .buttons a {
	border: 1px solid #e7e7e7;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 7px 15px;
	background: #fff;
	color: #8d8d8d;
}
.galleryFlst2 .buttons {
	margin: 15px 0;
	padding: 15px;
}
.galleryFlst2 .buttons a.active, .galleryFlst2 .buttons a:hover {
	background: #3E4095;
	color: #fff;
}
.galleryFlst2 .item {
	display: none;
}
/*Block 2*/
.block2 {
	padding: 0;
	margin: 0;
}
.block2 .row {
	margin: 0;
	padding: 0;
}
.block2 .row .left {
	margin: 0;
	padding: 0;
}
.block2 .row .right {
	margin: 0;
	padding: 0;
	background-position: left top;
	background-size: cover;
}
.block2 .row .right .overlay {
	background: #0e4d6c;
	background: rgba(14, 77, 108,.75);
	padding: 46px;
	margin: 0;
}
.statistics .row .right .overlay {
	padding: 46px;
}
.block2 .row .right .header2 {
	margin: 0;
}
.block2 .row .right  p {
	color: white;
}
.block2 .row .right .overlay h3 {
	margin-top: 10px;
	color: white;
}
.block2 .row .item {
	padding: 0;
	margin: 0;
}
.block2 .row .item a img {
	width: 100%;
}
.header h1 {
	color: white;
	font-size: 4em;
	line-height: 163px;
	font-size: 40px;
}
/** blog item */
.content {
	margin-top: 30px;
}
.blog-item  .fullWidth img {
	width: 100%;
}
.blog-item h2 {
	font-size: 24px;
	font-weight: bold;
	color: #3E4095;
}
.blog-item  hr {
	margin-left: 0;
}
.blog-item iframe {
	width: 100%;
}
.blog-item .blog-content .info a {
	color: #adb3b6;
	display: inline-block;
	margin-right: 20px;
}
.blog-item .blog-content .info a:last-child {
	margin: 0;
}
.blog-item .blog-description {
	line-height: 23px;
	font-size: 14px;
	margin: 18px 0;
}
.blog-item .blog-content .info a span {
	padding-right: 5px;
}
.blog-item .blog-url a {
	color: #3E4095;
	font-weight: bold;
}
.blog-item .blog-url a span {
	padding-left: 4px;
}
.blog-item  .overlay {
	padding: 0;
	background: red;
}
.blog-item  .overlay-wrapper {
	background: rgba(240,87,51,0.7);
	text-align: center;
	padding: 22px 30px 22px 37px;
}
.blog-item  .overlay-wrapper h2 {
	font-size: 24px;
	color: white;
}
.blog-item  .overlay-wrapper p a {
	line-height: 28px;
	font-size: 18px;
	color: white;
	display: block;
	margin: 25px 0;
}
.blog-item .image {
	margin: 0;
	padding-top: 0;
	padding-right: 0;
}
.blog-item  .blog-content a.btn-blue-border {
	padding: 10px 15px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.twoColumns .blog-content, .twoColumns  h2 {
	margin-top: 0;
	padding-top: 0;
}
/*inline slider */
.inlineslider .carousel-control {
	text-align: left;
}
.inlineslider .carousel-control .fa {
	top: 48%;
	left: 0;
	margin: -23px 0 0 0;
	position: relative;
	font-size: 14px;
	padding: 16px 11px;
	background: #3E4095;
	color: white;
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.inlineslider .right.carousel-control {
	text-align: right;
}
/* pagination */
.pagelink {
	text-align: left;
}
.pagelink ul {
	margin: 0 0 30px 0;
	padding: 0;
}
.pagelink ul li {
	list-style-type: none;
	padding: 0;
	display: inline-block;
	margin: 0;
	vertical-align: bottom;
	margin-right: 15px;
}
.pagelink ul li a {
	color: #bcbcbc;
	border: 2px solid  #bcbcbc;
	width: 45px;
	line-height: 42px;
	height: 45px;
	display: block;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	font-size: 14px;
	text-decoration: none;
}
.pagelink ul li:last-child {
	margin-right: 0;
}
.pagelink ul li a:hover, .pagelink ul li.active a {
	background: #3E4095;
	border-color: #3E4095;
	color: white;
}
.pagelink ul li a:hover {
	text-decoration: none;
}
/** sidebar */
.sidebar {
	float: right;
	width: 270px;
}
.leftSidebar {
	float: left;
}
.sidebar-block {
	margin-bottom: 50px;
}
.sidebar-block  h2 {
	color: #3E4095;
	font-size: 24px;
}
.sidebar-block  hr {
	margin-left: 0;
}
.sidebar-block ul.sidebar-menu {
	padding: 0;
}
.sidebar-block ul.sidebar-menu  > li > a:before {
	content: '';
	position: absolute;
	left: 11px;
	text-align: left;
	width: 30px;
	font-family: FontAwesome;
	font-size: 12px;
	color: #3E4095;
}
.sidebar-block ul.sidebar-menu  > li.active > a:before {
	content: '';
	color: white;
}
.sidebar-block ul.sidebar-menu > li {
	list-style-type: none;
	padding: 0;
	position: relative;
	line-height: 35px;
	border-top: 1px solid #e9e9e9;
}
.sidebar-block ul.sidebar-menu > li a {
	font-size:  16px;
	color: #3E4095;
	display: block;
	padding-left: 30px;
	text-decoration: none;
}
.sidebar-block ul.sidebar-menu  li a:hover {
	background: #3E4095;
	color: white;
}
.sidebar-block ul.sidebar-menu > li.active > a {
	background: #3E4095;
	color: white;
}
.sidebar-block ul.sidebar-menu > li ol li a {
	font-size:  16px;
	color: #3E4095;
	display: block;
	text-decoration: none;
}
.sidebar-block ul.sidebar-menu  li ol {
	margin: 0;
	padding: 0;
}
.sidebar-block ul.sidebar-menu  li ol > li:before {
	content: '';
	position: absolute;
	left: 47px;
	text-align: left;
	width: 30px;
	font-family: FontAwesome;
	font-size: 12px;
	color: #3E4095;
}
.sidebar-block ul.sidebar-menu  li ol > li {
	list-style-type: none;
	padding: 0 0 0 43px;
	position: relative;
	line-height: 35px;
	border-top: 1px solid #e9e9e9;
}
.sidebar-block ul.sidebar-menu > li:last-child {
	border-bottom: 1px solid #e9e9e9;
}
/*Recent Articles */
.sidebar  .recent-articles .item {
}
.sidebar  .recent-articles .item .rarticle-info {
	padding: 0 0 30px;
}
.sidebar  .recent-articles .item .rarticle-info h3 {
	color: #3E4095;
	font-size: 18px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 5px;
	color: #3E4095;
}
.sidebar  .recent-articles .item .rarticle-info p {
	font-size: 14px;
	line-height: 23px;
	color: #3E4095;
}
.sidebar  .recent-articles .item .rarticle-info .author {
	font-size: 14px;
	color: #3E4095;
	text-align: left;
	padding: 0;
	margin: 0;
	line-height: 14px;
}
.sidebar  .recent-articles .item .rarticle-info .date {
	font-size: 14px;
	color: #BE8D48;
	text-align: right;
	padding: 0;
	line-height: 14px;
	margin: 0;
}
.sidebar  .recent-articles .item .rarticle-info .date a {
	color: #BE8D48;
}
.sidebar .sidebar-block .readMore {
}
.sidebar .sidebar-block .readMore a {
	font-size: 14px;
	font-family: Lato;
	color: #BE8D48;
}
.sidebar .sidebar-block .readMore a span {
	text-decoration: none;
	padding-left: 5px;
	font-size: 18px;
	position: relative;
	top: 1px;
}
.sidebar .sidebar-block .images a {
	padding-left: 20px;
	display: inline-block;
	padding-bottom: 20px;
}
.sidebar .latest-twitter {
	margin-top: 15px;
}
.sidebar .latest-twitter p {
	font-size: 14px;
	color: #2b5c73;
	margin-top: 8px;
	margin-bottom: 20px;
}
.sidebar .info .twitter-user {
	color: #3E4095;
	font-size: 14px;
}
.sidebar .info .twitter-date {
	float: right;
	color: #BE8D48;
	font-size: 14px;
}
.sidebar .info span > .fa {
	font-size: 18px;
	padding-right: 5px;
}
.search-input-span {
	border: 1px solid #ccc;
	display: block;
	padding: 0 10px 0 0;
}
.search-input-span .fa {
	color: #838383;
	font-size: 18px;
}
.search-input-span input {
	line-height: 30px;
	display: inline-block;
	width: 222px;
	border: none;
	padding-left: 5px;
	height: 30px;
}
.sidebar .sidebar-block .images a:nth-child(3n+1) {
	padding-left: 0;
}
.twitter-item .twitter-date {
	position: relative;
	right: 0;
}
.grid-item {
	width: 190px;
	padding: 0;
	margin: 0;
}
.grid-item-380 {
	width: 380px;
	margin: 0;
	padding: 0;
}
/**Tag Cloud*/
.tag-cloud a {
	font-size: 11px;
	text-transform: uppercase;
	background: none;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	padding: 1px 10px;
	line-height: 25px;
	margin-bottom: 10px;
	display: inline-block;
	height: 25px;
	border: 1px solid #bcbcbc;
	color: #bcbcbc;
	margin: 5px 5px 5px 0;
	text-decoration: none;
}
.tag-cloud a:hover {
	color: #fff;
	background: #3E4095;
	border: 1px solid #3E4095;
}
/*Newsletter */
#instructors .box {
	display: none;
}
.newsletter-sidebar .newsletter-info {
	background: #BE8D48;
	color: #fff;
	padding: 10px 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.newsletter-sidebar .newsletter-info h4 {
	color: #fff;
	font-size: 22px;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 32px;
	margin-bottom: 0;
}
.newsletter-sidebar .newsletter-info h6 {
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 15px;
}
.newsletter-sidebar .newsletter-info {
	text-align: center;
}
.overlay-style1 .image a {
	position: relative;
	display: block;
	padding: 0;
	margin: 0 0px;
}
.overlay-style1 .image a img {
	width: 100%;
}
.overlay-style1 .info {
	position: absolute;
	top: 0;
	z-index: 1;
	left: 10px;
}
.overlay-style1 .info a {
	display: block;
	background: #3E4095;
	width: 46px;
	color: #fff;
	text-decoration: none;
	padding: 8px 0;
}
.overlay-style1 .info a span {
	display: block;
}
.overlay-style1 .info a  span.fa {
	font-size: 14px;
	text-align: center;
	color: #dfeeed;
}
.overlay-style1 .info a  span.count {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	font-family: Lato;
}
.overlay-style1 .info a:hover {
	background: #3E4095;
}
.overlay-style1 .image a {
	overflow: hidden;
}
.overlay-style1 .image a .overlay {
	top: auto;
	display: block;
	position: absolute;
	padding: 10px;
	bottom: -400px;
	opacity: 0;
	background: #fff;
	-webkit-transition: all 500ms;
	-moz-transition: all 500ms;
	transition: all 500ms;
}
.overlay-style1 .image a:hover .overlay {
	bottom: 0;
	opacity: 1;
}
.overlay-style1 .image a .overlay .title {
	font-size: 18px;
	font-family: Lato;
	font-weight: bold;
	display: block;
	text-align: center;
	margin: 0 0 10px 0;
}
.overlay-style1 .image a .overlay .dots3 {
	display: block;
	text-align: center;
	padding: 0 0 15px 0;
}
.overlay-style1 .image a .overlay .content {
	display: block;
	line-height: 23px;
	font-size: 14px;
	margin: 0;
}
.overlay-style1 .owl-controls .owl-buttons {
	display: none;
}

.overlay-style1 .owl-controls .owl-page span,
#courses .owl-controls .owl-page span {
	background: #3E4095;
}
#courses .owl-controls .owl-page {
	margin-top: 15px;
	margin-bottom: 5px;
}

#services .owl-controls .owl-page span {
	background: #3E4095;
}
#services .owl-controls .owl-page {
	margin-top: 15px;
	margin-bottom: 5px;
}

.overlay-style1 .owl-controls .owl-page span,
#clients .owl-controls .owl-page span {
	background: #fff;
}
#clients .owl-controls .owl-page {
	margin-top: 15px;
	margin-bottom: 5px;
}
blockquote:after {
	content: "\201D";
	top: 0;
	right: 25px;
	font-family: Arial;
	position: absolute;
	line-height: 1em;
	font-size: 300px;
	color: #dbebec;
}
blockquote {
	position: relative;
	background: #f1f6f8;
	padding: 30px 140px 30px 30px;
	border: none;
	color: #3E4095;
	min-height: 145px;
	margin-top: 15px;
}
.blockquote-reverse, blockquote.pull-right {
	border: none;
}
.blockquote-reverse:after, blockquote.pull-right:after {
	left: 25px;
	content: "\201C";
	right: auto;
}
blockquote  a {
	color: #3E4095;
}
.info-author .author-image {
	width: 120px;
}
.img-rounded {
	border: 2px solid #f4f4f4;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
}
.info-author .author-info {
	width: 71%;
}
.info-author .author-info h3, .info-author .author-info h3 a {
	color: #3E4095;
	font-size: 18px;
}
.info-author .author-info h4, .info-author .author-info h4 a {
	color: #BE8D48;
	font-size: 14px;
}
.info-author .more-articles {
	float: right;
	clear: right;
	text-align: right;
	line-height: 98px;
}
.info-author .more-articles, .info-author .more-articles a {
	color: #3E4095;
}
.info-author .more-articles a span {
	padding-left: 10px;
	font-size: 18px;
	line-height: 18px;
}
/**Post Social Icons*/
.social-icons-article, .social-icons-article ul {
	padding: 0;
	margin: 0;
}
.social-icons-article ul li a {
	color: #bcbcbc;
	border: 1px solid #bcbcbc;
	padding: 5px;
	display: inline-block;
	width: 35px;
	height: 35px;
	text-align: center;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.social-icons-article ul li a:hover {
	color: #3E4095;
	text-decoration: none;
}
/** Related Post */
.related-post {
	padding-top: 30px;
	clear: both;
}
.related-posts {
	clear: both;
	padding-top: 5px;
}
.related-posts hr {
	display: none;
}
.related-posts .post-image img {
	width: 100%;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.related-post h3 {
	margin-bottom: 0;
}
.related-posts .post-date , .related-posts .post-date {
	margin-top: 8px;
}
.related-posts .post-date, .related-posts .post-date a {
	color: #3E4095;
	line-height: 29px;
}
.related-posts .post-title , .related-posts .post-title a {
	color: #3E4095;
	line-height: 29px;
	font-size: 18px;
}
.related-posts .read-more , .related-posts .read-more a {
	line-height: 29px;
	color: #BE8D48;
	font-size: 14px;
}
.related-posts .read-more span {
	padding-left: 8px;
	font-size: 18px;
	position: relative;
	top: 1px;
}
/**Comments */
#comments {
	padding-top: 30px;
	clear: both;
}
#comments .comment-content:before {
	background: transparent url(../images/quote3.png) no-repeat;
	content: " ";
	width: 29px;
	height: 35px;
	position: absolute;
	top: 50px;
	left: -28px;
}
#comments .author:before {
	background: transparent url(../images/quote2.png) no-repeat;
}
#comments  hr {
	display: block;
	float: none;
	margin-left: 0;
}
#comments .comment-content {
	margin-left: 30px;
	background: #f4f4f4;
	color: #5c5c5c;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 15px;
	position: relative;
}
#comments .author {
	background: #3E4095;
	color: #fff;
}
#comments .author {
}
#comments .comment .avatar {
	float: left;
	width: 100px;
}
#comments .comment .avatar img {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	border: 2px solid #f4f4f4;
}
#comments .comment .comment-content {
	float: left;
	width: 75%;
}
#comments .comment .comment-content .comment-info {
	font-size: 16px;
	color: #3E4095;
}
#comments .comment .comment-content .comment-info .date {
	float: right;
	font-size: 14px;
	color: #BE8D48;
}
#comments .comment .comment-content .comment-info .date span {
	padding-right: 5px;
	color: #BE8D48;
}
#comments .comment .comment-content .reply {
	text-align: right;
}
#comments .comment .comment-content .reply a {
	color: #BE8D48;
}
#comments .comment {
	clear: both;
	margin: 30px 0;
	overflow: hidden;
}
#comments .comment .author .comment-info  a {
	color: #dcfffd;
}
#comments .comment .author .comment-info .date span,
#comments .comment .author .comment-info .date {
	color: #fff;
}
#comments .comment .author .reply a {
	color: #fff;
}
#comments .respond {
	margin-left: 100px;
}
.footer-contact-form {
	margin-bottom: 70px;
}
.footer-contact-form textarea {
	min-height: 180px;
}
.footer-contact-form input[type="email"], .footer-contact-form input[type="text"], .footer-contact-form input[type="date"], .footer-contact-form input[type="file"] {
	line-height: 45px;
	height: 45px;
}
/**Messages */
.bg-primary, .bg-success, .bg-info, .bg-warning, .bg-danger {
	padding: 15px;
}
.bg-primary span, .bg-success span, .bg-info span, .bg-warning span, .bg-danger span {
	font-size: 20px;
	padding: 15px;
}
.bg-success {
	color: green;
}
.bg-info {
	color: #027dbb;
}
.bg-warning {
	color: #333;
}
.bg-danger {
	color: #bb0202;
}
/* Lines*/
hr.left {
	margin-left: 0;
}
hr.right {
	margin-right: 0;
}
/*Page not found 404*/
.notfound404 {
	background: url(../images/background.gif);
	padding: 60px;
	text-align: center;

}
.notfound404 h1 {
	font-size: 120px;
	line-height: 1em;
	color: #0e4d6c;
	font-family: Roboto;
	font-style: italic;
}
.notfound404 p {
	font-size: 24px;
	line-height: 39px;
	color: #0e4d6c;
	margin: 40px 0 50px 0;
	font-family: Roboto;
	font-weight: 100;
}
#carousel-homepage h2 {
	color: #fff;
}
/*Background Images*/
.featured_image {
	
	background-image: url('../images/blog-featured.jpg');
}
.overlay-image1  {
	background-image: url('../images/770x248.png');
}
.image-768x801 {
	background-image: url(../images/768x801.jpg);
}
.image-1600x442{
	
	background: transparent url(../images/1600x442.jpg) no-repeat 0 50px;
}
.image-1366x534{
	
	background-image: url(../images/1366x534.jpg);
}
.image-1690x532{
	
	background-image: url(../images/1690x532.jpg);
}
.percent-78{
	
	width: 78%;
}
.percent-78{
	
	width: 53%;
}
.percent-93{
	
	width: 53%;
}

/*start responsive */
@media (max-width: 1268px) {
.meet-our-instructors .items {
	width: 600px;
}
.parallax, .parallax .overlay {
	height: 310px;
}
.newsletter {
	padding: 20px 0 39px;
}
.fullColumnImage .row .item .icon {
	margin:  0;
}
.contact .form-control {
	width: 100%;
}
#recent_from_the_blog .comments, .fullColumnImage .row .item .overlay p {
	display: none;
}
}
@media (max-width: 1200px) {
.promo .container .item h2 {
	font-size: 1.2em;
}
/* welcome to our website triangle after */
.fa-wrapper:before {
	height: 208px;
	width: 208px;
	top: -103px;
}
.fa-wrapper {
	height: 138px;
}
.block1 .wrapper .fa-wrapper .icon-fa1 {
	font-size: 5em;
	top: 23px;
}
#main-nav  ul {
	margin: 0 auto;
	width: 100%;
	text-align: center;
}
/* main menu collapse sooner*/
#mainMenu  .navbar-header {
	float: none;
}
#mainMenu  .navbar-left, .navbar-right {
	float: none !important;
}
#mainMenu  .navbar-toggle {
	display: block;
	margin-top: 20px;
}
#mainMenu  .navbar-collapse {
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
}
#mainMenu  .navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px;
}
#mainMenu  .navbar-collapse.collapse {
	display: none!important;
}
#mainMenu  .navbar-nav {
	float: none !important;
	margin-top: 7.5px;
}
#mainMenu  .navbar-nav > li {
	float: none;
	text-align: center;
	display: block;
	margin: 0 auto;
}
#mainMenu  .navbar-nav>li>a {
	padding-top: 10px;
	padding-bottom: 10px;
}
#mainMenu  .collapse.in {
	display: block !important;
}
#mainMenu  .navbar-nav .open .dropdown-menu {
	position: static;
	float: none;
	width: auto;
	margin-top: 0;
	background-color: transparent;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
/*slider */
.main-slider .carousel-inner .item h2 {
	font-size: 2.5em;
	margin-bottom: 3%;
}
.main-slider .carousel-inner .item p {
	font-size: 1.2em;
	margin-bottom: 3%;
	line-height: 1.5em;
}
.main-slider .carousel-inner .item .call2action {
	margin-bottom: 0;
}
/* one package*/
.onepackage .fa-wrapper::before {
	content: " ";
	height: 155px;
	left: 18.9%;
	position: absolute;
	top: -78.5px;
	transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);

	width: 155px;
}
.icon-fa2 {
	font-size: 3em;
}
.onepackage .fa-wrapper {
	height: 110px;
}
.onepackage h2 {
	font-size: 1.2em;
}
.onepackage .price h3 {
	font-size: 2.8em;
}
.onepackage .price p {
	font-size: 1em;
}
.onepackage ul > li {
	font-size: 1em;
	padding: 0 0 0 10px;
}
.footer-light .imagerighttext .text {
	width: 210px;
}
/* parallax form */
.formP .col {
	line-height: 60px;
}
.formP .col3 {
	float: none;
	clear: both;
	margin: 15px auto;
}
#comments .respond {
	margin-left: 0;
}

}
@media (max-width: 1170px) {
.statistics .row .right .overlay {
	margin: 0;
}
.block2 .row .right {
	clear: both;
}
.slide2 .carousel-caption {
	width: 200px;
}
}
@media (max-width: 1015px){
	.packages *{
		
		margin-left: 0;
		margin-right: 0;
	}
}
@media (max-width: 1001px) {
.top-menu .social_icons {
	display: none;
}
}
/**  991px width max  */
@media (max-width: 991px) {
.container {
	width: 100%;
}
.newsletter .container .row  > div {
	float: none;
	width: 100%;
	text-align: center;
}
.newsletter .container .row  .toRightCol {
	width: 385px;
	margin: 0 auto;
}
#intro2 .toRightCol {
	position: inherit;
	width: 100%;
	text-align: center;
}
.promo .middleColumn {
	display: none;
}
#intro2 .container .row > div {
	width: 100%;
}
#recent_from_the_blog .item {
	margin-bottom: 50px;
}
.promo .container .col-sm-3 {
	width: 49%;
	float: left;
	margin-bottom: 20px;
}
.promo .container .col-sm-3 p {
	padding-left: 32px;
}
.promo .container hr {
	margin: 40px auto 0;
}
/* welcome to our website triangle after */
.fa-wrapper:before {
	height: 155px;
	left: 19%;
	top: -79px;
	width: 155px;
}
.fa-wrapper {
	height: 100px;
}
.block1 .wrapper .fa-wrapper .icon-fa1 {
	font-size: 4em;
	top: 15px;
}
.block1 h2 {
	font-size: 1.8em;
	margin: 12px auto 10px;
}
.block1 p {
	font-size: 1em;
	padding: 0 15px;
}
.block1 .dots-3 {
	height: 30px;
}
.block1 .dots-3 p {
	font-size: 30px;
}
.container what-students-say .items .item {
	width: 100px;
	background: red;
}
.what-students-say .items .rows .avatar {
	width: 20%;
	float: left;
}
.what-students-say .items .rows .item .quote {
	float: right;
	width: 78%;
}
.what-students-say .items .rows .item {
	clear: both;
}
.what-students-say .items .rows .item  > div {
	margin-bottom: 20px;
}
.what-students-say  .pagination {
	clear: both;
}
.how_to_learn_german_online .info {
	width: 100%;
}
.how_to_learn_german_online .info p {
	height: auto;
}
.how_to_learn_german_online .info .call2action {
	margin: 25px auto;
}
.how_to_learn_german_online .videos p {
	float: left;
	margin-right: 10px;
}
.how_to_learn_german_online {
	background: none;
}
/* one package*/
.onepackage .fa-wrapper::before {
	content: " ";
	height: 117px;
	left: 20.4%;
	position: absolute;
	top: -59.5px;
	transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 117px;
	;
}
.icon-fa2 {
	font-size: 3em;
}
.onepackage .fa-wrapper {
	height: 71px;
}
.onepackage .fa {
	font-size: 2em;
	top: 18px;
}
.onepackage h2 {
	font-size: 1.1em;
}
.onepackage .price h3 {
	font-size: 1.9em;
}
.onepackage .price p {
	font-size: 0.9em;
}
.onepackage ul > li {
	font-size: 0.9em;
	padding: 0 0 0 10px;
}
.onepackage ul > li:before {
	left: 25px;
}
.onepackage ul {
	margin: 25px 0 0 20px;
}
.formP .col, .form-control {
	width: 46%;
}
.parallax, .parallax .overlay {
	height: 410px;
}
.slide2 .carousel-caption h2 {
	font-size: 2em;
}
.slide2 .carousel-caption h3 {
	font-size: 1.8em;
}
.slide2 .carousel-caption p {
	font-size: 1em;
	display: none;
}
.slide2 .call2action .btn-white {
	display: none;
}
.form-control {
	width: 100%;
}
#contact-form {
	text-align: center;
}
}
@media (max-width: 970px) {
#contact-form .alignLeft {
	margin-left: auto;
}
#comments .comment {
	width: 100%;
	float: none;
	overflow: inherit;
}
#comments .comment .comment-content,
#comments .comment .avatar {
	float: none;
	width: 100%;
	text-align: center;
}
#comments .comment .avatar {
	padding-bottom: 30px;
}
#comments .comment-content:before {
	top: -30px;
	left: 45%;
	transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
}
.container .col-sm-8 {
	padding-right: 25px;
}
#meet_our_instructors .container {
	margin: 0;
	width: 100%;
}
.meet-our-instructors .items {
	width: 440px;
}
#comments .comment-content {
	margin-left: 0;
	margin-right: 0;
}
/* slider */
.main-slider .carousel-inner .item h2 {
	font-size: 2em;
	margin-bottom: 3%;
}
.main-slider .carousel-inner .item p {
	font-size: 1em;
	margin-bottom: 3%;
}
.main-slider .carousel-inner .item .call2action {
	margin-bottom: 0;
}
.dark-footer {
	text-align: center;
}
.dark-footer hr, .footer-light hr {
	margin-left: auto;
}
.copywritesection , .footer-light {
	text-align: center;
}
.footer-light .imagerighttext {
	text-align: left;
}
.copywritesection .container .row > div {
	float: none;
	width: 100%;
	margin: 15px 0;
	text-align: center;
}
.how_to_learn_german_online {
	text-align: center;
}
.how_to_learn_german_online hr {
	margin-left: auto;
}
.how_to_learn_german_online .action-button {
	clear: both;
}
.footer-light .fl-wrapper {
	min-height: auto;
}
.info-author .author-image {
	float: none;
	margin: 0 auto;
}
.info-author .more-articles, .info-author .more-articles {
	clear: both;
	float: none;
	width: 100%;
	text-align: center;
	clear: both;
}
.info-author .author-info {
	margin: 0 auto;
	text-align: center;
	float: none;
	width: 100%;
	clear: both;
}
.info-author .more-articles {
	line-height: 30px;
}
.social-icons-article .list-inline li {
	margin-bottom: 15px;
}
.social-icons-article .list-inline {
	text-align: center;
}
.social-icons-article .list-inline li {
	text-align: center;
}
.related-post {
	text-align: center;
}
hr.alignLeft {
	margin-left: auto;
	margin-right: auto;
	float: none;
}
}
/* applying css rules to max width 768px */
@media (max-width: 769px) {
	#welcome .block1 >div{
		
		width: 100%;
		float: none;
		clear: both;
	}
.container .col-sm-8 {
	padding-right: 15px;
}
.related-posts .post-image img {
	max-width: 270px;
}
.sidebar {
	float: none;
	text-align: center;
	margin: 0 auto;
	width: 100%;
	max-width: 270px;
}
.sidebar-block hr {
	margin-left: auto;
}
.sidebar-block ul {
	text-align: left;
}
.recent-articles .item {
	margin-bottom: 15px;
}
.sidebar .recent-articles .item .rarticle-info h3 {
	margin-top: 15px;
}
.fullColumnImage .row .item .overlay p {
	display: block;
}
.meet-our-instructors .items {
	width: 220px;
}
.top-menu {
	display: none;
}
.main-slider,.slide2 {
	
	display: none;
}
.customtab1 {
	margin-bottom: 60px;
}

.block1 .wrapper {
	width: 360px;
	margin: 0 auto;
	position: relative;
	padding: 0;
}
.fa-wrapper {
	height: 171px;
}
.fa-wrapper:before {
	height: 255px;
	left: 14%;
	top: -128px;
	width: 255px;
}
.block1 .col-sm-4 {
	margin-bottom: 40px;
}
.block1 .wrapper .fa-wrapper .icon-fa1 {
	font-size: 6em;
	top: 35px;
}
.block1 h2 {
	margin: 12px auto 22px;
}
.block1 p {
	padding: 0;
}
.packages .onepackage {
	margin-bottom: 45px;
}
.packages .row > div {
	width: 360px;
	margin: 0 auto;
}
.packages .fa-wrapper:before {
	height: 235px;
	left: 17%;
	top: -117px;
	width: 235px;
}
.packages .fa-wrapper {
	height: 171px;
}
.onepackage .fa {
	font-size: 4em;
	top: 35px;
}
.onepackage h2 {
	font-size: 1.8em;
}
.onepackage .price h3 {
	font-size: 3.3em;
}
.onepackage ul > li::before {
	left: 45px;
}
.onepackage ul > li {
	font-size: 1.2em;
	line-height: 2.5em;
	list-style-type: none;
	margin: 0;
	padding: 0 0 0 25px;
}
.onepackage ul {
	margin: 25px 0 0 40px;
	padding: 0;
}
.packages .row  > div.heading1 {
	margin-bottom: 15px;
	float: none;
}
.formP .col, .form-control {
	width: 98%;
	text-align: center;
}
.parallax, .parallax .overlay {
	height: 310px;
}
.statistics .row .right .overlay {
	padding-left: 15px;
	padding-right: 15px;
}
/**homeepage 2*/
.fromtheblog2 .items .item {
	text-align: center;
	max-width: 268px;
	margin-left: auto;
	margin-right: auto;
}
.fromtheblog2 .items .item img {
	display: block;
	clear: both;
	float: none;
}
.fromtheblog2 .items .item .dots3 {
	margin-bottom: 15px;
}
.related-post .read-more {
	margin-bottom: 20px;
}
.related-posts hr {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 40px;
}
}
@media (max-width: 575px) {
.promo .container .col-sm-3 {
	width: 100%;
}
.map {
	display: none;
}
table tr, table td, table th {
	display: block;
}
}
/* start mobile responsive */
@media (max-width: 480px) {

blockquote {
	padding: 15px;
}
blockquote:after {
	content: '';
}
.leftimg, .rightimg {
	float: none;
	padding-left: 0;
	padding-right: 0;
	width: 100%;
}
.statistics .row .left >div {
	width: 100%;
	clear: both;
}
.newsletter .toRightCol .btn-blue, .newsletter .toRightCol .form-control {
	display: block;
	float: none;
	width: 100%;
	margin-bottom: 20px;
	max-width: 	inherit;
}
#intro2 .toRightCol img {
	width: 100%;
}
.tabs li {
	float: none;
	width: 100%;
	text-align: center;
}
.accordion .panel h4 {
	font-size: .9em;
	letter-spacing: 0;
}
/* home slide, hide h2*/
.main-slider .carousel-inner .item h2 {
	display: none;
}
.block1 .wrapper {
	width: 300px;
	margin: 0 auto;
	position: relative;
	padding: 0;
}
.fa-wrapper:before {
	height: 155px;
	left: 19%;
	top: -79px;
	width: 155px;
}
#accordion .panel > .panel-heading h4 .fa {
	width: 45px;
	height: 45px;
	font-size: 1em;
	line-height: 45px;
}
#accordion .panel > .panel-collapse .panel-body {
	margin: 15px 0 15px 20px;
}
#accordion .panel > .panel-collapse .panel-body {
	padding-left: 25px;
}
.panel-group .panel + .panel {
	margin-top: 18px;
}
#accordion .panel > .panel-heading h4 .collapsed .fa:after {
	top: 55px;
	left: 19px;
}
#accordion .panel > .panel-heading h4 .collapsed .fa {
	margin-bottom: 34px;
}
#carousel-homepage .call2action , .carousel-indicators {
	display: none;
}
.newsletter .container .row .toRightCol {
	width: 100%;
	padding:  0 10px;
}
.fullWidth, .packages .row > div {
	width: 100%;
}
.clients .clients-logos {
	text-align: center;
	width: 240px;
}
.footer-light .imagerighttext .img,  .footer-light .imagerighttext .text {
	display: block;
	float: none;
	clear: both;
	text-align: center;
	margin: 15px  auto;
}
.nav-tabs > li {
	border-top: 1px solid #086491;
}
.what-students-say .items .rows .item > div {
	margin-bottom: 50px;
}
.what-students-say .items .rows .avatar, .what-students-say .items .rows .item .quote {
	display: block;
	float: none;
	text-align: center;
	width: 100%;
}
.what-students-say .review:before {
	left: 49%;
	transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	top: -30px;
}
.what-students-say .items .rows .item {
	margin-top: 30px;
}
.what-students-say .items p {
	padding: 0;
}
.what-students-say .items .rows .avatar, .what-students-say .items .rows .item .review .info * {
	display: block;
	position: inherit;
	margin-bottom: 15px;
}
}
/* 320 mobile*/
@media (max-width: 362px) {
.main-slider .carousel-inner .item .call2action {
	display: none;
}
.fullWidth, .packages .row > div {
	width: 100%;
}
.packages .fa-wrapper::before {
	height: 180px;
	width: 180px;
	left: 19%;
	top: -91px;
}
.onepackage .fa, .block1 .wrapper .fa-wrapper .icon-fa1 {
	font-size: 3em;
	top: 20px;
}
.block1 .wrapper {
	width: 250px;
}
.fa-wrapper:before {
	height: 181px;
	left: 14%;
	top: -91px;
	width: 179px;
}
}
@media (min-width: 990px){
	.promo .middleColumn {	
	
		width: 600px;
		margin: 30px 0 0 19%;
		left: 0;
		
	}
	.heading1 {
		
		margin-top: 0;
	}
}
@media (min-width: 1031px){
	.promo .middleColumn {	
	
	
		margin: 30px 0 0 20%;
	
		
	}	
}
@media (min-width: 1074px){  .promo .middleColumn {	margin: 30px 0 0 21.3%; }	}
@media (min-width: 1115px){  .promo .middleColumn {	margin: 30px 0 0 22%; }	}
@media (min-width: 1200px){  .promo .middleColumn {	margin: 30px 0 0 25.5%; }	}
@media (min-width: 1279px){  .promo .middleColumn {	margin: 30px 0 0 28%; }	}
@media (min-width: 1410px){  .promo .middleColumn {	margin: 30px 0 0 29%; }	}
@media (min-width: 1539px){  .promo .middleColumn {	width: 660px; }	}
@media (min-width: 1600px ){
	.promo .middleColumn {

		width: 705px;
		margin: 30px 0 0 28%;
		left: 0;
	}	
.main-slider .item img{width: 100%;}
	}
@media (min-width: 1690px){  .promo .middleColumn {	margin: 30px 0 0 29%; }	}
@media (min-width: 1746px){  .promo .middleColumn {	margin: 30px 0 0 30%; }	}
@media (min-width: 1830px){  .promo .middleColumn {	margin: 30px 0 0 31%; }	}


@media (min-width: 1920px ){
	.promo .middleColumn {

		margin: 30px 0 0 31.5%;
		left: 0;
	}	
}
@media (min-width: 2071px){  .promo .middleColumn {	margin: 30px 0 0 33%; }	}
@media (min-width: 2213px){  .promo .middleColumn {	margin: 30px 0 0 34%; }	}
@media (min-width: 2398px){  .promo .middleColumn {	margin: 30px 0 0 35.3%; }	}	
	
.form-control
{
	line-height: 45px;
    height: 45px;
}

.ajaxLoading {
    display:    none;
    position:   fixed;
    z-index:    1000;
    top:        0;
    left:       0;
    height:     30%;
    width:      30%;
    background: rgba( 255, 255, 255, .8 )
                url('../images/ajax-loader.gif')
                50% 50%
                no-repeat;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .ajaxLoading {
    display: block;
}

.help-block{ color: #9C1D1D; }

.whatsapp-icon {
	color: white !important;
	background: green;
	font-weight: bold;
}